
import { Component, Vue, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { TipTap } from '@/mixins/TipTap';

import * as ContentServices from '@/services/ContentServices';
import { ROOT_ACTIONS } from "@/store/actions";
@Component
export default class Index extends mixins(TipTap) {
    pages = [
        {
            name: 'Home',
            slug: 'home',
        },
        // {
        //     name: 'Over ons',
        //     slug: 'over-ons'
        // },
        {
            name: "Factcheck Suggereren",
            slug: 'factcheck-suggereren'
        },
        {
            name: 'Privacy',
            slug: 'privacy'
        },
        {
            name: 'Cookies',
            slug: 'cookies'
        }
    ]

    loading = {
        content: false,
        submit: false
    }

    tab = 0

    content = {

    } as Record<string, { type: string, value: string }>

    @Watch('tab', { immediate: true })
    async onTabChange() {
        this.loading.content = true
        const result = await ContentServices.GetContent(this.pages[this.tab].slug)
        if (result) {
            this.content = result.content
        } else {
            this.content = {}
        }
        this.loading.content = false
    }


    async save() {
        try {
            this.loading.submit = true
            await ContentServices.SaveContent(this.pages[this.tab].slug, this.content)
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'success',
                text: 'Content opgeslagen'
            })
        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: 'Er is iets mis gegaan met het opslaan van de content'
            })
        }
        this.loading.submit = false
    }
}
